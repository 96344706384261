<template>
    <DataTable :value="servicos" responsiveLayout="stack">
        <Column header="Data" :sortable="true" style="width:1rem;">
            <template #body="{ data }">
                {{ formatDate(data.data) }}
            </template>
        </Column>
        <Column field="estagio" header="Estágio" :sortable="true" style="width:1rem;" />
        <Column header="Talhão" :sortable="true" style="width:1rem;">
            <template #body="{ data }">
                {{ data.nomeTalhao.toUpperCase() }}
            </template>
        </Column>
        <Column field="servico" header="Serviço" :sortable="true" style="width:1rem;" />
        <Column field="tipoServico" header="Tipo serviço" :sortable="true" style="width:1rem;" />
        <Column field="observacao" header="Observação" :sortable="true" style="width:1rem;" />
        <Column field="nomeAgronomo" header="Agronômo" :sortable="true" style="width:1rem;" />
        <Column header="Fotos" :sortable="false" style="width:1rem;">
            <template #body="{ data }">
                <Button 
                    title="Fotos" 
                    icon="pi pi-images" 
                    class="p-button-rounded p-button-secondary p-button-outlined"
                    :disabled="!data.possuiImagem"
                    @click="abrirGaleria(data.idServicoRealizado)"
                />
            </template>
        </Column>
    </DataTable>
    
    <Galleria :value="fotos" :responsiveOptions="responsiveOptions" :showIndicators="true"
              :numVisible="9" :circular="true" :fullScreen="true" :showItemNavigators="true"
              v-model:visible="galeria" :showThumbnails="false" containerStyle="max-width: 70%">
        <template #item="slotProps">
            <img :src="slotProps.item.imagem" :alt="slotProps.item.alt" style="width: 100%; display: block" />
        </template>
        <template #thumbnail="slotProps">
            <img :src="slotProps.item.thumbnail" :alt="slotProps.item.alt" style="width: 25%; display: block;" />
        </template>
    </Galleria>
</template>

<script lang="js">
    import AtendimentoService from '../../service/AtendimentoService';
    import DossieAgriculaService from '../../service/DossieAgriculaService';
    import Formatacao from '../../utilities/Formatacao';

    export default {
        name: 'AtendimentosServico',
        emits: ["qtdServico"],
        props: {
            matricula: {
                type: Number,
                required: true
            },
            dataInicio: {
                type: String,
                required: true
            },
            dataFim: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                ultimaDataInicio: null,
                ultimaDataFim: null,
                servicos: [],
                fotos: [],
                galeria: false,
                responsiveOptions: [
                    {
                        breakpoint: '1024px',
                        numVisible: 5
                    },
                    {
                        breakpoint: '768px',
                        numVisible: 3
                    },
                    {
                        breakpoint: '560px',
                        numVisible: 1
                    }
                ],
            }
        },

        created() {
            this.ultimaDataInicio = this.dataInicio;
            this.ultimaDataFim = this.dataFim;
            this.atualizarServicos();
        },

        updated() {
            if(this.ultimaDataInicio !== this.dataInicio 
            || this.ultimaDataFim !== this.dataFim) {
                this.ultimaDataInicio = this.dataInicio;
                this.ultimaDataFim = this.dataFim;
                this.atualizarServicos();
            }
        },

        methods: {
            atualizarServicos() {
                AtendimentoService.getServicos(
                    this.matricula,
                    this.dataInicio,
                    this.dataFim
                ).then(({ data }) => {
                    if(data) {
                        this.servicos = data;
                    }

                    this.$emit('qtdServico', this.servicos.length);
                });
            },

            formatDate(data) {
                return Formatacao.formatDate(data);
            },

            abrirGaleria(idServicoRealizado) {
                DossieAgriculaService.getImagensPorIdServicoRealizado(idServicoRealizado)
                    .then(({ data }) => {
                        const fotos = [];

                        for(let item of data) {
                            fotos.push({
                                thumbnail: "data:image/jpeg;base64," + item.imagem,
                                imagem: "data:image/jpeg;base64," + item.imagem,
                                alt: item.descricao
                            });
                        }

                        this.fotos = fotos;
                        this.galeria = true;
                    });
            }
        }
    }
</script>