<template>
    <DataTable :value="ocorrencias" responsiveLayout="stack">
        <Column header="Data" :sortable="true" style="width:1rem;">
            <template #body="{ data }">
                {{ formatDate(data.data) }}
            </template>
        </Column>
        <Column field="estagio" header="Estágio" :sortable="true" style="min-width:8rem; max-width:10rem;" />
        <Column field="descricaoTipoOcorrencia" header="Tipo ocorrência" :sortable="true" style="min-width:8rem; max-width:15rem;" />
        <Column field="idOcorrencia" header="Ocorrência" :sortable="true" style="width:2rem;" />
        <Column header="Perda" :sortable="true" style="width:1rem; text-align: right;">
            <template #body="{ data }">
                {{ formatDecimal(data.percentualPerda, 0, 2) }} %
            </template>
        </Column>
        <Column field="nivelComprometimento" header="Comprometimento" :sortable="true" style="min-width:2rem; max-width:15rem;" />
        <Column header="Tamanho área" :sortable="true" style="min-width:2rem; max-width:10rem; text-align: right;">
            <template #body="{ data }">
                {{ formatDecimal(data.tamanhoArea, 0, 5) }}
            </template>
        </Column>
        <Column field="nomeAgronomo" header="Agronômo" :sortable="true" style="min-width:2rem; max-width:10rem;" />
        <Column header="Fotos" :sortable="false" style="width:1rem;">
            <template #body="{ data }">
                <Button 
                    title="Fotos" 
                    icon="pi pi-images" 
                    class="p-button-rounded p-button-secondary p-button-outlined"
                    :disabled="!data.possuiImagem"
                    @click="abrirGaleria(data.idOcorrencia)"
                />
            </template>
        </Column>
    </DataTable>
    
    <Galleria :value="fotos" :responsiveOptions="responsiveOptions" :showIndicators="true"
              :numVisible="9" :circular="true" :fullScreen="true" :showItemNavigators="true"
              v-model:visible="galeria" :showThumbnails="false" containerStyle="max-width: 70%">
        <template #item="slotProps">
            <img :src="slotProps.item.imagem" :alt="slotProps.item.alt" style="width: 100%; display: block" />
        </template>
        <template #thumbnail="slotProps">
            <img :src="slotProps.item.thumbnail" :alt="slotProps.item.alt" style="width: 25%; display: block;" />
        </template>
    </Galleria>
</template>

<script lang="js">
    import AtendimentoService from '../../service/AtendimentoService';
    import DossieAgriculaService from '../../service/DossieAgriculaService';
    import Formatacao from '../../utilities/Formatacao';

    export default {
        name: 'AtendimentosOcorrencia',
        emits: ["qtdOcorrencia"],
        props: {
            matricula: {
                type: Number,
                required: true
            },
            dataInicio: {
                type: String,
                required: true
            },
            dataFim: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                ultimaDataInicio: null,
                ultimaDataFim: null,
                ocorrencias: [],
                fotos: [],
                galeria: false,
                responsiveOptions: [
                    {
                        breakpoint: '1024px',
                        numVisible: 5
                    },
                    {
                        breakpoint: '768px',
                        numVisible: 3
                    },
                    {
                        breakpoint: '560px',
                        numVisible: 1
                    }
                ],
            }
        },

        created() {
            this.ultimaDataInicio = this.dataInicio;
            this.ultimaDataFim = this.dataFim;
            this.atualizarOcorrencias();
        },

        updated() {
            if(this.ultimaDataInicio !== this.dataInicio 
            || this.ultimaDataFim !== this.dataFim) {
                this.ultimaDataInicio = this.dataInicio;
                this.ultimaDataFim = this.dataFim;
                this.atualizarOcorrencias();
            }
        },

        methods: {
            atualizarOcorrencias() {
                AtendimentoService.getOcorrencias(this.matricula, this.dataInicio, this.dataFim)
                    .then(({ data }) => {
                        if(data) {
                            this.ocorrencias = data;
                        }

                        this.$emit('qtdOcorrencia', this.ocorrencias.length);
                    })
                    .catch((e) => {
                        console.log("Atendimentos - Ocorrencias: ", e);
                    }) ;
            },

            formatDate(data) {
                return Formatacao.formatDate(data);
            },

            formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
            },

            abrirGaleria(idOcorrenciaIdentificada) {
                DossieAgriculaService.getImagensPorIdOcorrenciaIdentificada(idOcorrenciaIdentificada)
                    .then(({ data }) => {
                        const fotos = [];

                        for(let item of data) {
                            if(item.imagem){
                                fotos.push({
                                    thumbnail: "data:image/jpeg;base64," + item.imagem.imagem,
                                    imagem: "data:image/jpeg;base64," + item.imagem.imagem,
                                    alt: item.descricao
                                });
                            }
                        }

                        this.fotos = fotos;
                        this.galeria = true;
                    });
            }
        }
    }
</script>

<style>
    .p-datatable-table .p-column-header-content {
        display: inline-flex !important;
    }
</style>