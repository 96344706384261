<template>
    <div class="col-12">
        <div class="card">
            <h5>Atendimento</h5>
            
            <div class="formgrid grid align-items-end">
                <div class="field col-6 md:col-2">
                    <label for="data-inicial">Data inicial</label>
                    <Calendar class="w-full" id="data-inicial" v-model="dataInicial" autocomplete="off" :maxDate="dataFinal" dateFormat="dd/mm/yy" />
                </div>
                <div class="field col-6 md:col-2">
                    <label for="data-final">Data final</label>
                    <Calendar class="w-full" id="data-final" v-model="dataFinal" autocomplete="off" :minDate="dataInicial" dateFormat="dd/mm/yy" />
                </div>
                <div class="field col-12 md:col-2">
                    <Button class="w-full" label="Consultar" icon="pi pi-search" @click="atualizarDataFiltro"  ></Button>
                </div>
            </div>

            <hr />

            <Accordion id="accordion-atendimentos">
                <AccordionTab :disabled="qtdAtendimento == 0">
                    <template #header>
                        <Badge :value="2" size="large" severity="success" class="mr-2">{{ qtdAtendimento }}</Badge>
                        Atendimentos
                    </template>
                    <Atendimentos 
                        :matricula="currentUser.matricula"
                        :dataInicio="filtroDataInicial"
                        :dataFim="filtroDataFinal"
                        @qtdAtendimento="setQtdAtendimento"
                    />
                </AccordionTab>
                <AccordionTab :disabled="qtdOcorrencia == 0">
                    <template #header>
                        <Badge :value="2" size="large" severity="info" class="mr-2">{{ qtdOcorrencia }}</Badge>
                        Ocorrências
                    </template>
                    <AtendimentosOcorrencia 
                        :matricula="currentUser.matricula"
                        :dataInicio="filtroDataInicial"
                        :dataFim="filtroDataFinal"
                        @qtdOcorrencia="setQtdOcorrencia"
                    />
                </AccordionTab>
                <AccordionTab :disabled="qtdRecomendacao == 0">
                    <template #header>
                        <Badge :value="2" size="large" severity="warning" class="mr-2">{{ qtdRecomendacao }}</Badge>
                        Recomendações
                    </template>
                    <AtendimentosRecomendacao 
                        :matricula="currentUser.matricula"
                        :dataInicio="filtroDataInicial"
                        :dataFim="filtroDataFinal"
                        @qtdRecomendacao="setQtdRecomendacao"
                    />
                </AccordionTab>
                <AccordionTab :disabled="qtdAplicado == 0">
                    <template #header>
                        <Badge :value="2" size="large" severity="danger" class="mr-2">{{ qtdAplicado }}</Badge>
                        Aplicações
                    </template>
                    <AtendimentosAplicado
                        :matricula="currentUser.matricula"
                        :dataInicio="filtroDataInicial"
                        :dataFim="filtroDataFinal"
                        @qtdAplicado="setQtdAplicado"
                    />
                </AccordionTab>
                <AccordionTab :disabled="qtdServico == 0">
                    <template #header>
                        <Badge :value="2" size="large" class="mr-2">{{ qtdServico }}</Badge>
                        Serviços
                    </template>
                    <AtendimentosServico 
                        :matricula="currentUser.matricula"
                        :dataInicio="filtroDataInicial"
                        :dataFim="filtroDataFinal"
                        @qtdServico="setQtdServico"
                    />
                </AccordionTab>
            </Accordion>
        </div>
    </div>
</template>

<script lang="js">
    import Atendimentos from '../../components/consulta/Atendimentos.vue';
    import AtendimentosServico from '../../components/consulta/AtendimentosServico.vue'
    import AtendimentosOcorrencia from '../../components/consulta/AtendimentosOcorrencia.vue'
    import AtendimentosRecomendacao from '../../components/consulta/AtendimentosRecomendacao.vue'
    import AtendimentosAplicado from '../../components/consulta/AtendimentosAplicado.vue';
    import Formatacao from '../../utilities/Formatacao';

    export  default {
        components: {
            Atendimentos,
            AtendimentosServico,
            AtendimentosOcorrencia,
            AtendimentosRecomendacao,
            AtendimentosAplicado
        },
        data() {
            return {
                dataInicial: Formatacao.subtrairMeses(new Date(), 6),
                dataFinal: new Date(),

                filtroDataInicial: null,
                filtroDataFinal: null,

                qtdAtendimento: 0,
                qtdServico: 0,
                qtdOcorrencia: 0,
                qtdRecomendacao: 0,
                qtdAplicado: 0,
            }
        },
        
        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},

        created() {
            this.atualizarDataFiltro();
        },

        methods: {
            setQtdAtendimento(quantidade) {
                this.qtdAtendimento = quantidade;
            },

            setQtdServico(quantidade) {
                this.qtdServico = quantidade;
            },

            setQtdOcorrencia(quantidade) {
                this.qtdOcorrencia = quantidade;
            },

            setQtdRecomendacao(quantidade) {
                this.qtdRecomendacao = quantidade;
            },

            setQtdAplicado(quantidade) {
                this.qtdAplicado = quantidade;
            },

            atualizarDataFiltro() {
                this.filtroDataInicial = Formatacao.formatDateCustom(this.dataInicial, 'DD-MM-YYYY');
                this.filtroDataFinal = Formatacao.formatDateCustom(this.dataFinal, 'DD-MM-YYYY');


                this.qtdAtendimento;
                this.qtdServico;
                this.qtdOcorrencia;
                this.qtdRecomendacao;
                this.qtdAplicado;
            }
        }
    }
</script>

<style>
    #accordion-atendimentos .p-badge.p-badge-lg {
        font-size: 0.8rem;
        min-width: 3rem;
        height: 3rem;
        line-height: 3rem;
    }
</style>