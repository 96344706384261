<template>
    <DataTable :value="recomendacoes" responsiveLayout="stack">
        <Column header="Data" :sortable="true" style="width:1rem;">
            <template #body="{ data }">
                {{ formatDate(data.data) }}
            </template>
        </Column>
        <Column field="nomeTalhao" header="Talhão" :sortable="true" style="min-width:8rem; max-width:10rem;" />
        <Column field="produto" header="Produto" :sortable="true" style="min-width:8rem; max-width:15rem;" />
        <Column field="modoAplicacao" header="Modo aplicação" :sortable="true" style="width:2rem;" />
        <Column field="dosagem" header="Dosagem" :sortable="true" style="width:1rem; text-align: right;" />
        <Column field="capacidadePulverizador" header="Capacidade" :sortable="true" style="width:3rem; text-align: right;" />
        <Column field="vazao" header="Vazão" :sortable="true" style="width:3rem; text-align: right;" />
        <Column field="nomeAgronomo" header="Agronômo" :sortable="true" style="min-width:2rem; max-width:10rem;" />
    </DataTable>
</template>

<script lang="js">
    import AtendimentoService from '../../service/AtendimentoService';
    import Formatacao from '../../utilities/Formatacao';

    export default {
        name: 'AtendimentosRecomendacao',
        emits: ["qtdRecomendacao"],
        props: {
            matricula: {
                type: Number,
                required: true
            },
            dataInicio: {
                type: String,
                required: true
            },
            dataFim: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                ultimaDataInicio: null,
                ultimaDataFim: null,
                recomendacoes: []
            }
        },

        created() {
            this.ultimaDataInicio = this.dataInicio;
            this.ultimaDataFim = this.dataFim;
            this.atualizarRecomendacoes();
        },

        updated() {
            if(this.ultimaDataInicio !== this.dataInicio 
            || this.ultimaDataFim !== this.dataFim) {
                this.ultimaDataInicio = this.dataInicio;
                this.ultimaDataFim = this.dataFim;
                this.atualizarRecomendacoes();
            }
        },

        methods: {
            atualizarRecomendacoes() {
                AtendimentoService.getRecomendacoes(
                    this.matricula,
                    this.dataInicio,
                    this.dataFim
                ).then(({ data }) => {
                    if(data) {
                        this.recomendacoes = data;
                    }

                    this.$emit('qtdRecomendacao', this.recomendacoes.length);
                });
            },

            formatDate(data) {
                return Formatacao.formatDate(data);
            },

            formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
                return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
            }
        }
    }
</script>

<style>
    .p-datatable-table .p-column-header-content {
        display: inline-flex !important;
    }
</style>